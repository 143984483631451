import { Component } from '@angular/core';
import { ApiSettingsLoader, IWebServicePageData } from '../services/ApiSettingsLoader';

@Component({
  selector: 'app-home',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent {

  public data: IWebServicePageData;

  constructor(settings: ApiSettingsLoader) {
    this.data = settings.data.ServicePage;
  }

  ngOnInit() {

  }
}
