import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApiClient } from './services/ApiClient';
import { ApiSettingsLoader } from './services/ApiSettingsLoader';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { PricesPageComponent } from './prices-page/prices-page.component';



export function settingsProviderFactory(provider: ApiSettingsLoader) {
  return () => provider.load();
}

@NgModule({
  providers: [
    ApiSettingsLoader,
    { provide: APP_INITIALIZER, useFactory: settingsProviderFactory, deps: [ApiSettingsLoader], multi: true },
    ApiClient,
  ],
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ContactPageComponent,
    ServicesPageComponent,
    TermsPageComponent,
    PricesPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/services', pathMatch: 'full'},
      { path: 'home', component: HomeComponent },
      { path: 'contact', component: ContactPageComponent },
      { path: 'services', component: ServicesPageComponent },
      { path: 'terms', component: TermsPageComponent },
      { path: 'prices', component: PricesPageComponent },
    ])
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
