import { Component, Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { ApiSettingsLoader } from './ApiSettingsLoader';

@Injectable({
  providedIn: 'root',
})

export class ApiClient {

  private id: number;

  constructor(private app: AppComponent, private settings: ApiSettingsLoader, private http: HttpClient) {
    this.id = 0;
  }

  //public logWebsiteVisit() {
  //  var params = {
  //    Url: window.location.href
  //  };

  //  this.call("log/website/visit", params, result => {
  //    console.log(result);
  //  }, error => { console.error(error); });
  //}

  //public call<T>(path: string, request: object, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void) {
  //  const httpOptions = {
  //    headers: new HttpHeaders({
  //      'Access-Control-Allow-Origin': '*',
  //      'Access-Control-Allow-Headers': '*',
  //      'API_TOKEN': this.settings.config.apiKey
  //    })
  //  };

  //  this.http.post<T>(this.settings.config.apiPath + path, request, httpOptions)
  //    .subscribe(res => next(this.cast<T>(res)),
  //      error,
  //      complete);
  //  this.id++;
  //}

  public cast<T>(input: any): T {
    return this.toCamel(input) as T;
  }

  public toCamel(o) {
    var that = this;
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function (value) {
        if (typeof value === "object") {
          value = that.toCamel(value)
        }
        return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = that.toCamel(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }


  ngOnInit() {

  }


}
