
<header class="animated fadeInUp" id="navbar">
  <section>
    <div class="row">
      <div class="col-lg float-left">
        <img src=".\assets\logo.png" class="logo"/>
      </div>
      <div class="col-lg float-right">
        <nav mat-tab-nav-bar mat-align-tabs="end">

          <a mat-tab-link [routerLink]="['/services']" #rla1="routerLinkActive" [active]="rla1.isActive" routerLinkActive>
            {{data.Services}}
          </a>
          <a mat-tab-link [routerLink]="['/terms']" #rla2="routerLinkActive" [active]="rla2.isActive" routerLinkActive>
            {{data.TermsAndConditions}}
          </a>
          <a mat-tab-link [routerLink]="['/prices']" #rla3="routerLinkActive" [active]="rla3.isActive" routerLinkActive>
            {{data.Prices}}
          </a>
          <a mat-tab-link [routerLink]="['/contact']" #rla4="routerLinkActive" [active]="rla4.isActive" routerLinkActive>
            {{data.Contact}}
          </a>

        </nav>
      </div>
    </div>
  </section>

</header>
