import { Component } from '@angular/core';
import { ApiSettingsLoader, IWebPricesPageData } from '../services/ApiSettingsLoader';

@Component({
  selector: 'app-home',
  templateUrl: './prices-page.component.html',
  styleUrls: ['./prices-page.component.scss']
})
export class PricesPageComponent {

  public data: IWebPricesPageData;

  constructor(settings: ApiSettingsLoader) {
    this.data = settings.data.PricesPage;
  }

  ngOnInit() {

  }
}
