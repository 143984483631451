import { Component } from '@angular/core';
import { ApiSettingsLoader, IWebTermsAndConditionsPageData } from '../services/ApiSettingsLoader';

@Component({
  selector: 'app-home',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent {

  public data: IWebTermsAndConditionsPageData;

  constructor(settings: ApiSettingsLoader) {
    this.data = settings.data.TermsAndConditionsPage;
  }

  ngOnInit() {

  }
}
