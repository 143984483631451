<section class="page-content">

  <div class="row">
    <h1 class="mat-h1 title">{{data.Title}}</h1>
  </div>

  <mat-card class="paragraph-card">
    <div *ngFor="let info of data.Info;">
      <mat-card-title>{{info.Title}}</mat-card-title>
      <mat-card-content>{{info.Value}}</mat-card-content>
    </div>
    
  </mat-card>



</section>
