import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiSettingsLoader {

  public data: IWebData;
  private url: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
    //this.url = "http://localhost:3000/";
  }

  load() {
    return new Promise((resolve, reject) => {
      this.http
        .post<IWebData>(this.url + 'api/get/data', {})
        .subscribe(response => {
          this.data = response;
          console.log(response);
          resolve(true);
        })
    })
  }

}

interface IWebData {
  Navigation: IWebNavigationData;
  ServicePage: IWebServicePageData;
  TermsAndConditionsPage: IWebTermsAndConditionsPageData;
  ContactPage: IWebContactPageData;
  PricesPage: IWebPricesPageData;
}

export class IWebNavigationData {
  Services: string;
  Contact: string;
  Prices: string;
  TermsAndConditions: string;
  Telektroonika: string;
}

export class IWebServicePageData {
  Title: string;
  Services: string;
}

export class IWebTermsAndConditionsPageData {
  Title: string;
  TermsAndConditions: string;
  Date: string;
  By: string;
}

export class IWebPricesPageData {
  Title: string;
  Prices: string;
}

export class IWebContactPageData {
  Title: string;
  Info: string;
}

