import { Component, HostListener, Inject, OnInit  } from '@angular/core';
import { ApiSettingsLoader, IWebNavigationData } from '../services/ApiSettingsLoader';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  
})

export class NavMenuComponent {
  public data: IWebNavigationData;

  constructor(settings: ApiSettingsLoader, @Inject(DOCUMENT) document) {
    this.data = settings.data.Navigation;
    //this.api.logWebsiteVisit();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }
}
