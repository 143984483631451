<section class="page-content">

  <div class="row">
    <h1 class="mat-h1 title">{{data.Title}}</h1>
  </div>

  <mat-card class="paragraph-card">
    <mat-card-content>
      <p *ngFor="let condition of data.Prices;" [innerHTML]="condition"></p>
    </mat-card-content>
    <br/>
  </mat-card>


</section>
