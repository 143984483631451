import { Component } from '@angular/core';
import { ApiClient } from '../services/ApiClient';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ApiClient],
})
export class HomeComponent {

  public api: ApiClient;

  constructor(api: ApiClient) {
    this.api = api;
    //this.api.logWebsiteVisit();
  }

  ngOnInit() {
    
  }
}
