<section class="page-content">

  <div class="row">
    <h1 class="mat-h1 title">{{data.Title}}</h1>
  </div>

  <mat-card class="paragraph-card">
    <mat-card-content>
      <p *ngFor="let condition of data.TermsAndConditions;" [innerHTML]="condition"></p>
    </mat-card-content>
    <br/>
    <mat-divider></mat-divider>
    <br/>
    <mat-card-footer>{{data.By}} - {{data.Date}}</mat-card-footer>
  </mat-card>


</section>
