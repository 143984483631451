<section class="page-content">

  <div class="row">
    <h1 class="mat-h1 title">{{data.Title}}</h1>
  </div>

  <div class="box float-left col-md-12" *ngFor="let service of data.Services;">
    <mat-card class="paragraph-card service-box" >
      <mat-card-title>{{service.Title}}</mat-card-title>
      <mat-card-content>{{service.Description}}</mat-card-content>
    </mat-card>
  </div>

</section>
