import { Component } from '@angular/core';
import { ApiSettingsLoader, IWebContactPageData } from '../services/ApiSettingsLoader';

@Component({
  selector: 'app-home',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {

  public data: IWebContactPageData;

  constructor(settings: ApiSettingsLoader) {
    this.data = settings.data.ContactPage;
  }

  ngOnInit() {

  }
}
